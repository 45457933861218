import languageData, { LocaleData } from '../languages'; // Must be relative (since it's used in gatsby-config.ts and absolute imports break from there)

const getLocaleDataFromString: (str: string) => LocaleData = (str: string) => {
	const firstPathName = str.split('/')[1];

	// Finds the property/language that matches the locale field
	for (const language in languageData) {
		if (languageData[language]?.locale === firstPathName) return languageData[language] || languageData.Default;
	}

	return languageData.Default;
};

export default getLocaleDataFromString;
